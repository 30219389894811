import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import { Storage } from '@ionic/storage';

import Login from '../views/Login.vue';
import Voertuig from '../views/Voertuig.vue';
import RitStops from '../views/RitStops.vue';
import RitStop from '../views/RitStop.vue';
import Ritten from '../views/Ritten.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/voertuig',
    name: 'Voertuig',
    component: Voertuig
  },
  {
    path: '/ritten',
    name: 'Ritten',
    component: Ritten
  },
  {
    path: '/rit/:id/ritstops',
    component: () => import('../views/RitStops.vue')
  },
  {
    path: '/rit/:ritId/ritstops/:ritStopId/:index',
    props: true,
    component: () => import('../views/RitStop.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  const authStorage = new Storage({
    name: 'tranreg',
    storeName: 'tranreg.auth'
  });

  await authStorage.create();
  const token = await authStorage.get('bearer_token');

  if (to.path !== '/' && token == null) {
    return { name: 'Login' }
  } else if (to.path === '/' && token != null) {
    return { name: 'Voertuig' }
  }
});

export default router;
