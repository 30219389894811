export function useDateFormatter() {

  const formatDate = (datestring: string) => {
    if (datestring == null) {
      return '';
    }

    const date = new Date(datestring);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const formattedMonth = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = formattedHours + ':' + formattedMinutes;

    return formattedDay + '-' + formattedMonth + '-' + date.getFullYear() + ' ' + strTime;
  };

  const getTime = (datestring: string) => {
    if (datestring == null) {
      return '';
    }

    const date = new Date(datestring);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return formattedHours + ':' + formattedMinutes;
  }

  return {
    formatDate,
    getTime
  };
}