<template>
  <ion-page>
    <ion-content :force-overscroll="false">
      <div class="container">
        <ion-row class="app-information">
          <img src="../../public/img/logo.png" alt="Logo" />
          <h1>TranReg</h1>
        </ion-row>
        <ion-row>
          <ion-button @click="openQRScannerModal" type="button" expand="full" color="success">
            <ion-icon size="small" slot="start" :icon="qrCode"></ion-icon>
            Scan QR-code
          </ion-button>
        </ion-row>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonRow,
  IonButton,
  IonIcon,
  modalController,
  alertController
} from '@ionic/vue';
import { qrCode } from 'ionicons/icons'
import { defineComponent } from 'vue';
import QRScannerModal from '../components/QRScannerModal.vue'

export default defineComponent({
  name: 'LoginPage',
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonButton,
    IonIcon
  },
  data() {
    return {
      qrCode,
      installPwaPrompt: null,
      installPwaAlert: null
    }
  },
  async created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPwaPrompt = e;
    });

    window.addEventListener('appinstalled', () => {
      this.installPwaPrompt = null;
    });

    this.installPwaAlert = await alertController.create({
      header: 'Installeer TranReg app',
      message: 'Deze app is nog niet geïnstalleerd op dit apparaat. Installeer de app voor optimaal gebruik.',
      buttons: [
        {
          text: 'Installeren',
          role: 'install',
          handler: () => {
            this.installPwaPrompt.prompt();
          },
        },
      ],
      backdropDismiss: false,
    });
  },
  methods: {
    async openQRScannerModal() {
      const modal = await modalController
        .create({
          component: QRScannerModal,
          cssClass: 'qrscanner',
          componentProps: {
            title: 'Scan QR-code',
            openCamera: true
          },
          swipeToClose: true
        })
      return modal.present()
    }
  },
  watch: {
    async installPwaPrompt(newValue) {
      if (newValue != null) {
        await this.installPwaAlert.present();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
ion-item {
  --padding-start: 8px;
  --padding-end: 8px;
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  .app-information {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 30px;
    }

    img {
      width: 100px;
    }
  }
}
</style>
