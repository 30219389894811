<template>
  <div v-if="!online" class="network-statusbar" :class="{ 'offline': !online }">
    <ion-icon size="small" slot="start" :icon="wifi"></ion-icon>
    <span>Let op: geen internet connectie</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { IonIcon, toastController } from '@ionic/vue';
import { wifi } from 'ionicons/icons'
import { useOnline } from '@vueuse/core'
import { Storage } from '@ionic/storage'
import axios from 'axios'

export default defineComponent({
  setup() {
    const online = useOnline();
    return {
      online
    }
  },
  data() {
    return {
      wifi,
      axiosInstance: null
    }
  },
  components: {
    IonIcon
  },
  watch: {
    async online(networkOnline) {
      if (networkOnline === true) {
        const toast = await toastController.create({
          message: 'Netwerk beschikbaar. Wijzigingen worden gesynchroniseerd.',
          position: 'bottom',
          cssClass: 'success-toast',
          duration: 4000,
          icon: wifi
        });

        await toast.present();

        await this.initAxios();

        const queueStorage = new Storage({
          name: 'tranreg',
          storeName: 'tranreg.queue'
        });

        await queueStorage.create();

        const storageKeys = await queueStorage.keys();

        if (storageKeys && storageKeys.length) {
          for (const storageKey of storageKeys) {
            const storageData = await queueStorage.get(storageKey)
            await this.saveRitStop(storageKey, storageData)
            await queueStorage.remove(storageKey);
          }
        }
      }
    }
  },
  methods: {
    async initAxios() {
      const vehicleStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.vehicle'
      });

      await vehicleStorage.create();
      const baseUrl = await vehicleStorage.get('issuer');

      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      const token = await authStorage.get('bearer_token');

      this.axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
    },
    async saveRitStop(ritStopId, storageData) {
      const data = JSON.parse(storageData)

      // Save signature
      if (data.signature && data.signature.mediaPayload != null) {
        await this.axiosInstance.post(`api/ritstop/${ritStopId}/melding`, data.signature)
      }

      // Save vrachtbrieven
      if (data.vrachtbrieven && data.vrachtbrieven.length) {
        for (const vrachtbrief of data.vrachtbrieven) {
          await this.axiosInstance.post(`api/ritstop/${ritStopId}/melding`, vrachtbrief)
        }
      }

      // Save photos
      if (data.photos && data.photos.length) {
        for (const photo of data.photos) {
          await this.axiosInstance.post(`api/ritstop/${ritStopId}/melding`, photo)
        }
      }

      // Save comment
      if (data.comment && data.comment.mediaPayload !== '') {
        await this.axiosInstance.post(`api/ritstop/${ritStopId}/melding`, data.comment)
      }

      // Save toeslagen
      if (data.toeslagen) {
        await this.axiosInstance.get(`api/ritstop/${ritStopId}/toeslagen`)
          .then(async (response) => {
            if (response && response.data && response.data.toeslagen) {
              for (const toeslag of response.data.toeslagen) {
                if (!data.toeslagen.map(x => x.toeslagId).includes(toeslag.toeslagId)) {
                  await this.axiosInstance.delete(`api/toeslag/${toeslag.toeslagId}`)
                }
              }
            }
          })

        for (const toeslag of data.toeslagen) {
          if (toeslag.toeslagId == null) {
            await this.axiosInstance.post(`api/ritstop/${ritStopId}/toeslag`, {
              toeslagCodeId: toeslag.toeslagCodeId,
              aantal: toeslag.aantal
            })
          }
        }
      }

      // Save emballages
      if (data.emballages) {
        await this.axiosInstance.get(`api/ritstop/${ritStopId}/emballages`)
          .then(async (response) => {
            if (response && response.data && response.data.emballages) {
              for (const emballage of response.data.emballages) {
                if (!data.emballages.map(x => x.idEmballage).includes(emballage.idEmballage)) {
                  await this.axiosInstance.delete(`api/emballage/${emballage.idEmballage}`)
                }
              }
            }
          })

        for (const emballage of data.emballages) {
          if (emballage.idEmballage == null) {
            await this.axiosInstance.post(`api/ritstop/${ritStopId}/emballage`, {
              emballagecode: emballage.emballagecode,
              referentie: emballage.referentie,
              geleverd: emballage.geleverd,
              retour: emballage.retour
            })
          }
        }
      }

      // Save ritstop status
      let ritStopStatusUrl = `api/ritstop/${ritStopId}/${data.ritStopStatus}`
      if (data.opleggerAankomst !== 0 && data.opleggerVertrek !== 0) {
        ritStopStatusUrl = `api/ritstop/${ritStopId}/${data.ritStopStatus}?opleggerIdAankomst=${data.opleggerAankomst}&opleggerIdVertrek=${data.opleggerVertrek}`
      }
      else if (data.opleggerAankomst !== 0) {
        ritStopStatusUrl = `api/ritstop/${ritStopId}/${data.ritStopStatus}?opleggerIdAankomst=${data.opleggerAankomst}`
      }
      else if (this.opleggerVertrek !== 0) {
        ritStopStatusUrl = `api/ritstop/${ritStopId}/${data.ritStopStatus}?opleggerIdVertrek=${data.opleggerVertrek}`
      }
      await this.axiosInstance.put(ritStopStatusUrl)
    }
  }
});
</script>

<style lang="scss" scoped>
.network-statusbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  font-size: 12px;

  &.offline {
    color: #fff;
    background: #e05353;
  }

  ion-icon {
    margin-right: 5px;
  }
}
</style>