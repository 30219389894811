
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonIcon } from '@ionic/vue';
import { list, qrCode } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { Storage } from '@ionic/storage';
import OfflineMessage from '../components/OfflineMessage.vue'

export default defineComponent({
  name: 'VehiclePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    OfflineMessage
  },
  data() {
    return {
      list,
      qrCode,
      code: ''
    }
  },
  async mounted() {
    const vehicleStorage = new Storage({
      name: 'tranreg',
      storeName: 'tranreg.vehicle'
    });

    await vehicleStorage.create();
    this.code = await vehicleStorage.get('code');
  },
  methods: {
    async resetVehicle() {
      const vehicleStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.vehicle'
      });

      await vehicleStorage.create();
      await vehicleStorage.clear();

      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      await authStorage.clear();

      this.$router.push({ path: '/' });
    }
  }
});
