
import { IonGrid, IonRow, IonCol, IonButton, IonItem, IonLabel, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useDateFormatter } from '@/composables/useDateFormatter';
import StartRitModal from '../components/StartRitModal.vue'
import EindeRitModal from '../components/EindeRitModal.vue'
import { flag, flagOutline } from 'ionicons/icons'
import axios from 'axios';
import { Storage } from '@ionic/storage';

export default defineComponent({
  name: 'RitListItem',
  components: {
    IonLabel,
    IonItem,
    IonButton,
    IonGrid,
    IonRow,
    IonCol
  },
  props: {
    rit: Object,
    index: Number
  },
  data() {
    return {
      flag, flagOutline,
      axiosInstance: null,
      ritStops: []
    }
  },
  methods: {
    isIos: () => {
      const win = window as any;
      return win && win.Ionic && win.Ionic.mode === 'ios';
    },
    async openStartRitModal() {
      const vehicleStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.vehicle'
      });

      await vehicleStorage.create();
      const baseUrl = await vehicleStorage.get('issuer');

      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      const token = await authStorage.get('bearer_token');
      const pincode = await authStorage.get('pincode');

      this.axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      await this.axiosInstance.get('api/stam/voertuig')
        .then(async (response) => {
          if (response && response.data && response.data.voertuigen) {
            await vehicleStorage.set('opleggers', response.data.voertuigen.filter(x => x.voertuigCode.toUpperCase() === 'OPLEGGER'));
          }
        })

      const modal = await modalController
        .create({
          component: StartRitModal,
          cssClass: 'startrit',
          componentProps: {
            title: 'Rit starten',
            rit: this.rit,
            opleggers: await vehicleStorage.get('opleggers')
          },
          swipeToClose: false
        });

      modal.onDidDismiss()
        .then(async (response) => {
          if (response && response.data) {
            const params = new URLSearchParams({
              pincode
            });

            if (response.data.kilometerstand) {
              params.append('StartKmStand', response.data.kilometerstand)
            }

            if (response.data.oplegger) {
              params.append('OpleggerId', response.data.oplegger)
            }

            if (response.data.werkelijkeStarttijd) {
              params.append('WerkelijkeStarttijd', response.data.werkelijkeStarttijd)
            }

            this.$router.push({ path: `/rit/${this.rit.ritId}/ritstops` });

            await this.axiosInstance.put(`api/rit/ritstart/${this.rit.ritId}?${params.toString()}`)
          }
        });

      return modal.present();
    },
    async openEindeRitModal() {
      const vehicleStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.vehicle'
      });

      await vehicleStorage.create();
      const baseUrl = await vehicleStorage.get('issuer');

      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      const token = await authStorage.get('bearer_token');
      const pincode = await authStorage.get('pincode');

      this.axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      await Promise.all([
        this.axiosInstance.get(`api/rit/${this.rit.ritId}/ritstops?pincode=${pincode}`)
          .then(async (response) => {
            if (response && response.data && response.data.stops) {
              this.ritStops = response.data.stops;
              await vehicleStorage.set('ritstops', response.data.stops);
            }
          }),
        this.axiosInstance.get('api/stam/voertuig')
          .then(async (response) => {
            if (response && response.data && response.data.voertuigen) {
              await vehicleStorage.set('opleggers', response.data.voertuigen.filter(x => x.voertuigCode.toUpperCase() === 'OPLEGGER'));
            }
          })
      ])

      const modal = await modalController
        .create({
          component: EindeRitModal,
          cssClass: 'einderit',
          componentProps: {
            title: 'Rit eindigen',
            rit: this.rit,
            opleggers: await vehicleStorage.get('opleggers'),
            ritStops: this.ritStops
          },
          swipeToClose: false
        });

      modal.onDidDismiss()
        .then(async (response) => {
          if (response && response.data) {
            const params = new URLSearchParams({
              pincode
            });

            if (response.data.kilometerstand) {
              params.append('StopKmStand', response.data.kilometerstand)
            }

            if (response.data.oplegger) {
              params.append('OpleggerId', response.data.oplegger)
            }

            if (response.data.werkelijkeEindtijd) {
              params.append('WerkelijkeStoptijd', response.data.werkelijkeEindtijd)
            }

            this.$router.push({ path: '/ritten' });

            await this.axiosInstance.put(`api/rit/riteinde/${this.rit.ritId}?${params.toString()}`)
          }
        });

      return modal.present();
    },
  },
  setup() {
    const { formatDate } = useDateFormatter();
    return { formatDate }
  }
});
