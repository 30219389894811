<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal">Sluit</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content :force-overscroll="false" class="ion-padding">
    <div class="form">
      <ion-item>
        <ion-label position="stacked">Kilometerstand:</ion-label>
        <ion-input type="number" inputmode="numeric" v-model="kilometerstand"
          placeholder="Vul kilometerstand in"></ion-input>
      </ion-item>
      <br />
      <ion-item>
        <ion-label position="stacked">Oplegger mee:</ion-label>
        <ion-select v-model="oplegger" interface="action-sheet" cancelText="Annuleren" placeholder="Kies">
          <ion-select-option value="">Geen</ion-select-option>
          <ion-select-option v-for="opl in opleggers" :key="opl.voertuigId" :value="opl.voertuigId">
            {{ opl.code }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>
    <br />
    <div class="werkelijkeStarttijd">
      <ion-label>Starttijd:</ion-label>
      <ion-datetime v-model="werkelijkeStarttijd" presentation="time"></ion-datetime>
    </div>
    <br />
    <ion-button type="button" expand="full" color="primary" @click="submit">
      <ion-icon size="small" slot="start" :icon="save"></ion-icon>
      Opslaan
    </ion-button>
  </ion-content>
</template>

<script>
import {
  modalController,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonInput,
  IonSelect,
  IonLabel,
  IonItem,
  IonIcon,
  IonSelectOption,
  IonDatetime,
  alertController
} from '@ionic/vue';
import { save } from 'ionicons/icons'

export default {
  name: 'StartRitModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton, IonInput,
    IonSelect,
    IonLabel,
    IonSelectOption,
    IonItem,
    IonIcon,
    IonDatetime
  },
  props: {
    title: {
      type: String,
      required: true
    },
    rit: {
      type: Object,
      required: true
    },
    opleggers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      save,
      kilometerstand: null,
      oplegger: null,
      werkelijkeStarttijd: null,
    }
  },
  mounted() {
    this.kilometerstand = this.rit.startKmStand
    this.oplegger = this.rit.oplegger?.voertuigId
    this.werkelijkeStarttijd = this.rit.werkelijkeStarttijd

    if (this.rit.werkelijkeStarttijd == null) {
      const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
      this.werkelijkeStarttijd = localISOTime
    }
  },
  methods: {
    async dismissModal() {
      await modalController.dismiss();
    },
    async submit() {
      await modalController.dismiss({
        kilometerstand: this.kilometerstand,
        oplegger: this.oplegger,
        werkelijkeStarttijd: this.werkelijkeStarttijd
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.werkelijkeStarttijd {
  padding-left: 20px;

  ion-label {
    display: block;
    margin-bottom: 10px;
  }
}
</style>