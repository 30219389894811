/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { alertController } from '@ionic/vue';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('Service worker is ready.');
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    async updated() {
      const alert = await alertController.create({
        header: 'Update beschikbaar',
        message: 'Er is een nieuwe versie van de app beschikbaar. Start de app opnieuw op om bij te werken.',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel'
          }
        ]
      });

      await alert.present();
    }
  })
}
