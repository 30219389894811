<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal">Sluit</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content :force-overscroll="false" class="ion-padding">
    <ion-loading v-if="loading" message="Aan het laden..." />
    <video ref="videoObject"></video>
  </ion-content>
</template>

<script>
import {
  modalController,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLoading,
  alertController,
  toastController
} from '@ionic/vue'
import { trash, add, alert } from 'ionicons/icons'
import QrScanner from "qr-scanner"

import axios from 'axios'
import { Storage } from '@ionic/storage'

export default {
  name: 'QRScannerModal',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLoading
  },
  props: {
    title: {
      type: String,
      required: true
    },
    openCamera: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      trash,
      add,
      alert,
      stream: null,
      scanActive: false,
      scanResult: null,
      loading: false,
      axiosInstance: null,
    }
  },
  mounted() {
    if (this.openCamera) {
      this.loading = true
      this.$refs.videoObject.addEventListener("loadeddata", (event) => {
        this.loading = false
      });
      this.qrScanner = new QrScanner(this.$refs.videoObject, this.handleQrScan, {
        onDecodeError: (error) => {
          if (error === "No QR code found") return;
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
      });
      this.qrScanner.start();
    }
  },
  methods: {
    async handleQrScan(result) {
      this.scanResult = result.data;
      this.qrScanner.destroy();
      this.qrScanner = null
      await this.authenticate();
    },
    async initAxios(baseUrl) {
      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      const token = await authStorage.get('bearer_token');

      this.axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
    },
    async dismissModal() {
      if (this.qrScanner) {
        this.qrScanner.destroy();
        this.qrScanner = null
      }

      await modalController.dismiss();
    },
    async authenticate() {
      try {
        const authStorage = new Storage({
          name: 'tranreg',
          storeName: 'tranreg.auth'
        });
        await authStorage.create();
        await authStorage.set('bearer_token', this.scanResult);

        const base64Url = this.scanResult.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const json = JSON.parse(jsonPayload);

        if (json) {
          if (json.pincodeVereist === '1') {
            await this.validateChauffeur(json, authStorage);
          } else {
            await authStorage.set('pincode', '');
            await this.login(json)
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async login(data) {
      const vehicleStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.vehicle'
      });

      await vehicleStorage.create();
      await vehicleStorage.set('code', data.code);
      await vehicleStorage.set('pincodeVereist', data.pincodeVereist === '1' ? true : false);
      await vehicleStorage.set('issuer', data.iss);

      this.dismissModal();
      this.$router.push({ path: '/voertuig' });
    },
    async validateChauffeur(json, authStorage) {
      await this.initAxios(json.iss);

      const alertPopup = await alertController.create({
        header: 'Chauffeurscode vereist',
        message: 'Geef uw chauffeurscode op om verder te gaan.',
        buttons: [
          {
            text: 'Annuleren',
            handler: async () => {
              await this.dismissModal()
            }
          },
          {
            text: 'Doorgaan',
            handler: async (data) => {
              this.axiosInstance.get(`api/stam/chauffeur/${data.code}`)
                .then(async (response) => {
                  if (response && response.data && response.data) {
                    await this.validatePincode(response.data.code, authStorage, json);
                  }
                })
                .catch(async () => {
                  const toast = await toastController.create({
                    message: 'Chauffeurscode niet bekend',
                    position: 'bottom',
                    cssClass: 'error-toast',
                    duration: 4000,
                    icon: alert
                  });

                  await toast.present();

                  this.dismissModal();
                  this.$router.push({ path: '/' });
                });
            },
          },
        ],
        inputs: [
          {
            type: 'text',
            name: 'code',
            placeholder: 'Typ hier de chauffeurscode',
          },
        ],
      });

      await alertPopup.present();
    },
    async validatePincode(chauffeurCode, authStorage, json) {
      const alertPopup = await alertController.create({
        header: 'Pincode vereist',
        message: 'Geef uw persoonlijke pincode op om verder te gaan.',
        buttons: [
          {
            text: 'Annuleren',
            handler: async () => {
              await this.dismissModal()
            }
          },
          {
            text: 'Doorgaan',
            handler: async (data) => {
              this.axiosInstance.post(`api/stam/chauffeur/testpincode/${chauffeurCode}/${data.pincode}`)
                .then(async (response) => {
                  if (response && response.status === 200) {
                    await authStorage.set('pincode', data.pincode);
                    await this.login(json);
                  }
                })
                .catch(async () => {
                  const toast = await toastController.create({
                    message: 'Pincode onjuist',
                    position: 'bottom',
                    cssClass: 'error-toast',
                    duration: 4000,
                    icon: alert
                  });

                  await toast.present();

                  this.dismissModal();
                  this.$router.push({ path: '/' });
                });
            },
          },
        ],
        inputs: [
          {
            type: 'number',
            name: 'pincode',
            placeholder: 'Typ hier de pincode',
          },
        ],
      });

      await alertPopup.present();
    }
  }
}
</script>

<style lang="scss" scoped>
video {
  width: 100%;
}
</style>