<template>
  <ion-page>
    <ion-header>
      <OfflineMessage />
      <ion-toolbar>
        <ion-buttons>
          <ion-back-button :text="getBackButtonText()" default-href="/voertuig"></ion-back-button>
          <ion-title>Ritten</ion-title>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :force-overscroll="true" :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="refresh($event)" disabled="false">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <ion-loading v-if="loading" message="Aan het laden..." />

      <ion-list>
        <div v-if="ritten.length" class="rit-list">
          <RitListItem v-for="(rit, index) in ritten" :key="rit.id" :rit="rit" :index="index + 1" />
        </div>
        <div v-else-if="!loading" class="no-results">Geen ritten gevonden</div>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonHeader, IonList, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonButtons, IonBackButton, IonLoading } from '@ionic/vue';
import RitListItem from '@/components/RitListItem.vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import { Storage } from '@ionic/storage';
import OfflineMessage from '../components/OfflineMessage.vue'

export default defineComponent({
  name: 'RidesPage',
  components: {
    IonContent,
    IonHeader,
    IonList,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    RitListItem,
    IonLoading,
    OfflineMessage
  },
  data() {
    return {
      getBackButtonText: () => {
        const win = window
        const mode = win && win.Ionic && win.Ionic.mode
        return mode === 'ios' ? 'Voertuig' : ''
      },
      ritten: [],
      loading: false,
      axiosInstance: null
    }
  },
  async ionViewWillEnter() {
    await this.getRitten();
  },
  methods: {
    async refresh(ev) {
      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      const pincode = await authStorage.get('pincode');

      this.axiosInstance.get(`api/rit?pincode=${pincode}`)
        .then((response) => {
          if (response && response.data && response.data.ritten) {
            this.ritten = response.data.ritten;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          ev.detail.complete();
        });
    },
    async getRitten() {
      this.loading = true;

      const vehicleStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.vehicle'
      });

      await vehicleStorage.create();
      const baseUrl = await vehicleStorage.get('issuer');

      const authStorage = new Storage({
        name: 'tranreg',
        storeName: 'tranreg.auth'
      });

      await authStorage.create();
      const token = await authStorage.get('bearer_token');
      const pincode = await authStorage.get('pincode');

      this.axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      this.axiosInstance.get(`api/rit?pincode=${pincode}`)
        .then(async (response) => {
          if (response && response.data && response.data.ritten) {
            this.ritten = response.data.ritten;
            await vehicleStorage.set('ritten', response.data.ritten);
          }
        })
        .catch(async (error) => {
          if (error.code === 'ERR_NETWORK') {
            this.ritten = await vehicleStorage.get('ritten');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
</script>

<style scoped>
ion-refresher {
  z-index: 1;
}

.no-results,
.rit-list {
  padding: 15px;
}
</style>